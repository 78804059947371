<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :width="isMobileScreen ? '90%' : 'fit-content'"
    custom-class="contract-type"
    v-on="$listeners">
    <div class="type-container">
      <span>I am creating a:</span>
      <div class="btn-container"  v-bind:class="{ 'btn-container-mobile': isMobileScreen }">
        <el-button
          style="width: 200px"
          type="primary"
          round
          @click="clickHandler(2)">
          Loading Schedule
        </el-button>
        <el-button
          style="width: 200px"
          type="primary"
          round
          @click="clickHandler(1)">
          Delivery Schedule
        </el-button>
        <el-button
          style="width: 200px"
          type="primary"
          round
          @click="clickHandler(3)">
          Ship to Ship (STS)
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'SelectScheduleType',
  data () {
    return {
      dialogVisible: false
    }
  },
  computed:{
    ...mapState({
      isMobileScreen: "isMobileScreen",
    }),
  },
  mounted () {
    this.dialogVisible = true
  },
  methods: {
    clickHandler (type) {
      this.$emit('selected', type)
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-type {
  .type-container {
    @include flex-center-column;

    > span {
      font-size: 20px;
    }

    .btn-container {
      display: flex;
      flex-wrap: wrap;
      margin: 20px auto;

    }
    .btn-container-mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 150px;
      .el-button,.el-button--small, .el-button--small.is-round{
        margin: 0 !important;
      }
    }
  }

}
</style>
