var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      {
        attrs: {
          visible: _vm.dialogVisible,
          width: _vm.isMobileScreen ? "90%" : "fit-content",
          "custom-class": "contract-type",
        },
        on: {
          "update:visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "type-container" }, [
        _c("span", [_vm._v("I am creating a:")]),
        _c(
          "div",
          {
            staticClass: "btn-container",
            class: { "btn-container-mobile": _vm.isMobileScreen },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "200px" },
                attrs: { type: "primary", round: "" },
                on: {
                  click: function ($event) {
                    return _vm.clickHandler(2)
                  },
                },
              },
              [_vm._v(" Loading Schedule ")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "200px" },
                attrs: { type: "primary", round: "" },
                on: {
                  click: function ($event) {
                    return _vm.clickHandler(1)
                  },
                },
              },
              [_vm._v(" Delivery Schedule ")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "200px" },
                attrs: { type: "primary", round: "" },
                on: {
                  click: function ($event) {
                    return _vm.clickHandler(3)
                  },
                },
              },
              [_vm._v(" Ship to Ship (STS) ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }